$(function () {
	const $select = $('#footer-quick-links-select');

	$select.on('change', function () {
		const href = $(this).val();

		if (/^https?:\/\//.test(href)) {
			window.open(href);
		} else {
			window.location = href;
		}
	});
});
