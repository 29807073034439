function initAccordion($this, openIcon, closeIcon) {
    var accordionWrapper = $this.find('.accordion__wrapper');

    accordionWrapper.find('.accordion__content:not(:first)').addClass('accordion-live');
    accordionWrapper
        .find('.accordion__item:first')
        .addClass('active-item')
        .find('.accordion-icon')
        .removeClass(closeIcon)
        .addClass(openIcon);
        

    accordionWrapper.find('.accordion__item').on('click', function () {
        $('.accordion__content').stop().slideUp();
        $('.accordion__item').not(this)
            .removeClass('active-item')
            .find('[data-fa-i2svg]')
            .removeClass(openIcon)
            .addClass(closeIcon);

        $(this)
            .toggleClass('active-item')
            .find('[data-fa-i2svg]')
            .toggleClass(openIcon)
            .toggleClass(closeIcon);

        var nextEl = $(this).next('div');
        nextEl.stop().slideToggle();
    });
}
//init Accordions

if ($('.accordion-plus').length) {
    $('.accordion-plus').each(function () {
        initAccordion($(this), "fa-minus", "fa-plus");
    })
}