class FiltersManager {
	constructor($wrap) {
		this.elements = {
			$wrap: $wrap,
			$container: $wrap.find('.filters-container'),
			$toggle: $wrap.find('.filters-toggle'),
			$buttons: $wrap.find('.filters-button'),
			$clear: $wrap.find('.filters-reset'),
			$fromField: $wrap.find('.filters-from'),
			$toField: $wrap.find('.filters-to')
		};

		this.categories = [];
		this.enabled = true;
		this.from = this.elements.$fromField.val();
		this.to = this.elements.$toField.val();
		this.visible = false;

		this.updateHandlers = [];

		this.init();
	}

	init() {
		const cm = this;

		this.elements.$buttons.each(function () {
			const value = $(this).data('value');
			const name = $(this).text().trim();

			cm.categories.push({
				value,
				name,
				active: false
			});
		});

		this.bindControls();

		this.adjustUI();
		this.onUpdate(this.adjustUI);
	}

	bindControls() {
		const cm = this;
		const elements = cm.elements;

		elements.$buttons.on('click', function () {
			if (cm.enabled) {
				cm.toggleCategory($(this).data('value'));
			}
		});

		elements.$fromField.datepicker({}).on('change', function () {
			if (cm.enabled) {
				cm.from = elements.$fromField.datepicker('getDate');
				cm.handleUpdate();
			}
		});

		elements.$toField.datepicker({}).on('change', function () {
			if (cm.enabled) {
				cm.to = elements.$toField.datepicker('getDate');
				cm.handleUpdate();
			}
		});

		elements.$clear.on('click', function () {
			if (cm.enabled) {
				cm.clear();
			}
		});

		elements.$toggle.on('click', function () {
			cm.visible = !cm.visible;
			cm.adjustUI();
		});
	}

	adjustUI() {
		const elements = this.elements;
		for (let i = 0; i < this.categories.length; i++) {
			const cat = this.categories[i];
			const $elem = this.elements.$buttons.filter(`[data-value="${cat.value}"]`);

			$elem.toggleClass('active', cat.active);
		}

		elements.$clear.toggleClass('visible', !this.empty(true));
		elements.$toggle.toggleClass('active', this.visible);

		if (this.visible) {
			elements.$container.slideDown({
				duration: 300,
				start: function () {
					$(this).css({
						display: $(window).width() >= 1025 ? 'flex' : 'block'
					});
				}
			});
		} else {
			elements.$container.slideUp(300);
		}

		elements.$fromField.prop('disabled', !this.enabled);
		elements.$toField.prop('disabled', !this.enabled);
		elements.$buttons.toggleClass('disabled', !this.enabled);
		elements.$clear.toggleClass('disabled', !this.enabled);
	}

	clear() {
		this.categories.forEach(c => c.active = false);

		this.elements.$fromField.val('');
		this.from = '';

		this.elements.$toField.val('');
		this.to = '';

		this.handleUpdate();
	}

	toggleCategory(value) {
		const cat = this.categories.filter(c => c.value === value)[0];
		const active = cat.active;

		cat.active = !active;

		this.handleUpdate();
	}

	getActiveCategories() {
		return this.categories.filter(c => c.active);
	}

	getActiveCount() {
		return this.getActiveCategories().length;
	}

	disable() {
		this.enabled = false;
		this.adjustUI();
	}

	enable() {
		this.enabled = true;
		this.adjustUI();
	}

	empty(includeFromTo) {
		return this.getActiveCount() === 0 && (!includeFromTo || (!this.from && !this.to));
	}

	onUpdate(action, priority = 10) {
		this.updateHandlers.push({
			action,
			priority
		});
	}

	handleUpdate() {
		const handlers = this.updateHandlers.sort((a, b) => b.priority - a.priority);

		for (let i = 0; i < handlers.length; i++) {
			handlers[i].action.apply(this);
		}
	}
}

export default FiltersManager;
