$('.intro-copy.read-more').each(function () {
	$(this).readmore({
		collapsedHeight: 400,
		moreLink: '<a href="#" aria-label="Read more">Read more</a>',
		lessLink: '<a href="#" aria-label="Close">Close</a>',
		blockProcessed($elem, collapsed) {
			if(collapsed) {
				$elem.addClass('read-more');
				$elem.toggleClass('read-more-open', !collapsed);
			}
		},
		beforeToggle(_, $elem, state) {
			$elem.toggleClass('read-more-open', !state);
			console.log($elem);
		}
	});
});
