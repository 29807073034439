const truncateSettings = {
	ellipsis: '...',
	wrap: 'letter',
	fallbackToLetter: 'letter',
	after: null,
	watch: false,
	height: 40,
	tolerance: 0
};

// Begin Load More Function
$(function () {
	$('.blog-listing').each(function () {
		const $container = $(this);
		const $list = $container.find('.blog-list');
		const $loadMore = $container.find('.blog-load-more');
		const category = $container.data('category');
		let postCount = $container.find('.blog-item').length;

		if (postCount.length < 4) {
			$loadMore.css('display', 'none');
		}

		$container.on('click', '.blog-load-more', function (e) {
			e.preventDefault();
			const url = '/blog/listings';
			postCount += 4;
			const data = {
				categoryName: category,
				pageSize: postCount,
				page: 0
			};

			$loadMore.remove();

			$.ajax({
				url,
				data,
				method: 'GET'
			}).done(function (response) {
				const newList = $.parseHTML(response, null, true);
				const $newList = $(newList);
				const $teasers = $newList.find('.blog-teaser');
				$teasers.on('update.dot', function () {
					$container.find('.blog-list-meta').remove();
					$container.find('.blog-item').remove();
					$list.append($newList);

					const meta = JSON.parse($list.find('.blog-list-meta').text());

					if (!meta.complete) {
						$list.append($loadMore);
					}
				});

				$teasers.dotdotdot(truncateSettings);
			});
		});
	});

});

$(document).ready(function () {
	$('.blog-teaser').dotdotdot(truncateSettings);
});
