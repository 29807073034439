import FiltersManager from '../shared/filters-manager';
import moment from 'moment';

class EventsModule {
	constructor(elem) {
		let $wrap = $(elem);
		this.elements = {
			$wrap: $wrap,
			$list: $wrap.find('.events-list'),
			$filters: $wrap.find('.filters-bar'),
			$loadContainer: $wrap.find('.events-load-more'),
			$loadAnimation: $wrap.find('.events-load-more-loader'),
			$loadButton: $wrap.find('.events-load-more-button'),
			$emptyMessage: $wrap.find('.events-empty-message')
		};

		this.complete = false;
		this.fetchLimit = 4;
		this.loadUrl = '/events/load-more';
		this.filters = new FiltersManager(this.elements.$filters);

		this.to = this.filters.to;
		this.from = this.filters.from;

		this.status = 'idle';
		this.init();
	}

	init() {
		const module = this;

		module.getEventElements().each(function () {
			module.prepEventItem(this);
		});

		module.bindControls();

		module.filters.onUpdate(() => {
			module.fetchEventsByDates().then(() => {
				module.adjustUI();
			});
		});

		module.adjustUI();
	}

	bindControls() {
		const module = this;
		this.elements.$loadButton.on('click', function (e) {
			e.preventDefault();
			module.loadMore();
		});
	}

	prepEventItem(elem) {
		const $elem = $(elem);
		const data = $elem.data('categories');

		if (data instanceof Array) return;

		const catArray = data.split(',');

		$elem.data('categories', catArray);
	}

	getFilteredEvents() {
		const $events = this.getEventElements();
		if (this.filters.empty()) return $events;
		const categories = this.filters.getActiveCategories().map(c => c.value);
		return $events.filter(function () {
			for (var i = 0; i < categories.length; i++) {
				if ($(this).data('categories').filter(c => c === categories[i]).length > 0) {
					return true;
				}
			}

			return false;
		});
	}

	getEventElements() {
		return this.elements.$wrap.find('.events-item');
	}

	set status(val) {
		this._status = val;
		this.adjustUI();
	}

	get status() {
		return this._status;
	}

	addNewEvents(res) {
		const module = this;
		const $new = $(res).filter(function () {
			return this.nodeType === 1;
		});

		$new.each(function () {
			module.prepEventItem(this);
			module.elements.$list.append(this);
		});

		if ($new.length < module.fetchLimit) {
			module.complete = true;
		}
	}

	loadMore() {
		this.status = 'loading';

		const module = this;
		const params = {
			take: this.fetchLimit,
			skip: this.getEventElements().length
		};

		console.log(module);

		if (module.from) {
			params.start = moment(module.from).format('YYYY-MM-DD');
		}

		if (module.to) {
			params.end = moment(module.to).format('YYYY-MM-DD');
		}

		console.log(params);

		$.ajax({
			url: this.loadUrl,
			method: 'GET',
			data: params
		}).done(res => {
			module.addNewEvents(res);
			module.status = 'idle';
		});
	}

	adjustUI() {
		const $active = this.getFilteredEvents();

		if (this.status !== 'loading' && this.filters.empty() && !this.complete) {
			this.elements.$loadButton.show();
		} else {
			this.elements.$loadButton.hide();
		}

		if (this.status === 'loading') {
			this.filters.disable();
			this.elements.$loadAnimation.show();
		} else {
			this.filters.enable();
			this.elements.$loadAnimation.hide();
		}

		this.getEventElements().hide();
		$active.show();
		
		if ($active.length) {
			this.elements.$emptyMessage.hide();
		} else {
			this.elements.$emptyMessage.show();
		}
	}

	fetchEventsByDates() {
		const module = this;
		return new Promise((res, rej) => {
			if (module.from === module.filters.from && module.to === module.filters.to) return res();

			module.complete = false;
			module.status = 'loading';
			module.getEventElements().remove();

			const data = {};

			if (module.filters.from) {
				data.start = moment(module.filters.from).format('YYYY-MM-DD');
			}

			if (module.filters.to) {
				data.end = moment(module.filters.to).format('YYYY-MM-DD');
			}

			$.ajax({
				data,
				url: module.loadUrl
			}).done(results => {
				module.from = module.filters.from;
				module.to = module.filters.to;
				module.status = 'idle';

				module.addNewEvents(results);

				res();
			});
		});
	}
}

$(function () {
	$('.events').each(function () {
		const module = new EventsModule(this);
	});
});
