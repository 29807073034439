$('.button-up').on('click', function (e) {
	e.preventDefault();
	this.parentNode.querySelector('[type=number]').stepUp();
});
$('.button-down').on('click', function (e) {
	e.preventDefault();
	this.parentNode.querySelector('[type=number]').stepDown();
});

// region Custom Parsley Validators
window.Parsley
	.addValidator('filemaxmegabytes', {
		requirementType: 'string',
		validateString: function (value, requirement, parsleyInstance) {

			if (!('FormData' in window)) {
				return true;
			}

			var file = parsleyInstance.$element[0].files;
			var maxBytes = requirement * 1048576;

			if (file.length == 0) {
				return true;
			}

			return file.length === 1 && file[0].size <= maxBytes;

		},
		messages: {
			en: 'File is too big'
		}
	})
	.addValidator('filemimetypes', {
		requirementType: 'string',
		validateString: function (value, requirement, parsleyInstance) {

			if (!('FormData' in window)) {
				return true;
			}

			var file = parsleyInstance.$element[0].files;

			if (file.length === 0) {
				return true;
			}

			var allowedMimeTypes = requirement.replace(/\s/g, '').split(',');
			return allowedMimeTypes.indexOf(file[0].type) !== -1;

		},
		messages: {
			en: 'This is not a proper image file.'
		}
	});

jQuery.extend(jQuery.expr[':'], {
	invalid: function (elem, index, match) {
		return elem.validity !== undefined && elem.validity.valid === false;
	}
});
// endregion

// region Masks
$(function () {
	$('[type="tel"]').mask('999-999-9999');
});
// endregion

// region Form Submission Handler
$('.form-ajax').on('submit', function (e) {
	e.preventDefault();

	const $form = $(this);
	const $wrap = $form.parent('.form-wrap');
	const $message = $wrap.find('.form-success');
	const $btn = $form.find('.button--formsubmit');

	const action = $form.attr('action');
	const data = new FormData($form[0]);

	// MDS-BAU-T870
    try {
		if ($("#PhoneNumber").val().length < 10) {
			$("#PhoneNumber").focus();
			$("#PhoneNumber").tooltip().on("Mobile number must be 10 digits");
		}
    } catch (e) { }	

	if ($form.parsley().isValid()) {
		$btn.prop('disabled', true);
		//Begin ajax method
		$.ajax({
			url: action,
			method: 'POST',
			data: data,
			processData: false,
			contentType: false,
			encType: 'multipart/form-data',
			success: function () {
				if (grecaptcha) grecaptcha.reset();

				if (typeof ga !== 'undefined') {
					ga('send', 'event', 'button', 'click', 'rfp');
				}

				$form[0].reset();
				$form.hide('slow');
				$message.show('slow');
				$message.focus();

			}, error: function (err) {
				alert(err.responseText);
			},
			complete: function () {
				$btn.prop('disabled', false);
			}
		});
	}
});
// endregion
