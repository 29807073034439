import { isEditMode } from './helpers';

$(function(){
    if(!isEditMode){
        $('.it-carousel').each(function () {
            const $container = $(this);
            const $mainSlider = $container.find('.it-carousel-main');
            const $contentSlider = $container.find('.it-carousel-content');
            const $controls = $container.find('.it-carousel-arrow-controls');


            $mainSlider.slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                autoplay: false,
                asNavFor: $contentSlider,
                appendArrows: $controls,
                prevArrow: "<a><i class='fal fa-arrow-left animate-arrow' role='presentation'></i></a>",
                nextArrow: "<a><i class='fal fa-arrow-right animate-arrow' role='presentation'></i></a>",
                autoplaySpeed: 10000,

            });
            $contentSlider.slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: false,
                autoplay: false,
                adaptiveHeight: true,
                asNavFor: $mainSlider,
            })
        });

        $('.ih-carousel').each(function () {
            const $container = $(this);
            const $mainSlider = $container.find('.ih-carousel-main');
            const $contentSlider = $container.find('.ih-carousel-content');
            const $controls = $container.find('.ih-carousel-arrow-controls');
            $mainSlider.slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                autoplay: false,
                asNavFor: $contentSlider,
                appendArrows: $controls,
                prevArrow: "<a><i class='fal fa-arrow-left animate-arrow' role='presentation'></i></a>",
                nextArrow: "<a><i class='fal fa-arrow-right animate-arrow' role='presentation'></i></a>",
                autoplaySpeed: 10000
            });

            $contentSlider.slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: false,
                autoplay: false,
                asNavFor: $mainSlider
            })
        });

        $('.award-slider').each(function(){
            const $container = $(this);
            $container.slick({
                infinite: true,
                slidesToShow: 5,
                slidesToScroll: 5,
                arrows: false,
                dots: false,
                autoplay: true,
                autoplaySpeed: 10000,
                responsive: [
                    {
                        breakpoint: 769,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            centerMode: true,
                            centerPadding: '60px'
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            centerMode: true
                        }
                    }
                ]
            });
        });

        $(".social-carousel-slick").each(function () {
            $(this).slick({
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                swipe: false,
                arrows: false,
                dots: false,
                cssEase: "linear",
                autoplay: true,
                speed: 3000,
                autoplaySpeed: 0,
                centerMode:false,
                pauseOnHover: false,
                pauseOnFocus: false,
                responsive: [
                    {
                        breakpoint: 769,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1

                        }
                    }
                ]
            });
        });

        $('.image-copy-carousel').each(function () {
					const $container = $(this);
					const $slider = $container.find('.image-copy-carousel-slider');
					const $controls = $container.find('.image-copy-controls')

					$slider.slick({
              infinite: true,
              arrows: true,
              dots: false,
              appendArrows: $controls,
              prevArrow: "<a class='slick-prev'><i class='fal fa-arrow-left animate-arrow' role='presentation'></i></a>",
              nextArrow: "<a class='slick-next'><i class='fal fa-arrow-right animate-arrow' role='presentation'></i></a>"
          });
        });

        // region Carousel Cursor Logic
        const $sliders = $('.it-carousel, .ih-carousel, .image-copy-carousel');
        const $sliderContents = $('.it-carousel-content, .ih-carousel-content');

        if($sliders.length && $(window).width() > 1200) {
            const speed = 18;
            const $cursor = $(`<div class="dynamic-cursor">
                                 <div class="dynamic-cursor-background"></div>
                                 <div class="dynamic-cursor-icon">
                                   <i class="fal fa-arrow-right" aria-label="Next"></i>
                                 </div>
                               </div>`);

            const $icon = $cursor.find('.dynamic-cursor-icon');

            const position = {
                offset: 0,
                x: -500,
                y: -500,
                rotation:{
                    current: 0,
                    target: 0
                }
            };

            $cursor.append($icon);
            $cursor.appendTo('body');

            $(window).on('mousemove', function(e) {
                position.x = e.clientX - $cursor.width() / 2;
                position.y = e.clientY - $cursor.height() / 2;
            });

            $sliders.on('mouseover mousemove', function (e) {
                const $slider = $(this);
                const center = $slider.offset().left + ($slider.width() / 2);

                if(e.clientX >= center) {
                    position.rotation.target = position.rotation.current >= 180 ? 360 : 0;
                } else {
                    position.rotation.target = 180;
                }
            });

            function calculateRotate() {
                const rotDiff = position.rotation.target - position.rotation.current;

                if (rotDiff > 0) {
                    position.rotation.current += speed;
                } else if (rotDiff < 0) {
                    position.rotation.current -= speed;
                }

                if(position.rotation.current >= 360) {
                    position.rotation.current = 0;
                    position.rotation.target = 0;
                }
            }

            function drawLoop() {
                $cursor.css({
                    left: position.x,
                    top: position.y
                });

                calculateRotate();

                $icon.css('transform', `translate(-50%, -50%) rotate(${position.rotation.current}deg)`);
                requestAnimationFrame(drawLoop);
            }

            requestAnimationFrame(drawLoop);

            $sliders.on('mouseover', function () {
                $cursor.addClass('arrow');
            });

            $sliders.on('mouseleave', function () {
                $cursor.removeClass('arrow');
            });

            $sliders.on('click', function () {
                if ($cursor.hasClass('arrow') && !$cursor.hasClass('pointer')) {
                    const step = now => $cursor.css('transform', `translateX(${now}px)`);
                    const duration = 200;
                    const offset = position.rotation.target === 180 ? -15 : 15;
                    $(position).stop().animate({ offset }, {
                        duration,
                        step,
                        done() {
                            $(position).stop().animate({ offset: 0 }, { duration, step });
                        }
                    });
                }
            });

            $sliderContents.on('mouseover', function () {
                $cursor.addClass('pointer');
            });

            $sliderContents.on('mouseleave', function () {
                $cursor.removeClass('pointer');
            });

        }


        // endregion
    }
});
