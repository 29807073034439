import FiltersManager from '../shared/filters-manager';

class PackagesModule {
	constructor(elem) {
		let $wrap = $(elem);
		this.elements = {
			$wrap: $wrap,
			$packages: $wrap.find('.packages-item'),
			$filters: $wrap.find('.filters-bar')
		};

		this.filters = new FiltersManager(this.elements.$filters);
		this.init();
	}

	init() {
		const module = this;
		this.elements.$packages.each(function () {
			module.prepPackageItem(this);
		});

		this.filters.onUpdate(() => {
			this.adjustUI();
		});

		this.adjustUI();
	}

	prepPackageItem(elem) {
		const $elem = $(elem);
		const catString = $elem.data('categories');
		const startString = $elem.data('start');
		const endString = $elem.data('end');

		if (!(catString instanceof Array)) {
			$elem.data('categories', catString.split('|'));
		}

		if (startString) {
			$elem.data('start', new Date(startString));
		}

		if (endString) {
			$elem.data('end', new Date(endString));
		}
	}

	getFilteredPackages() {
		const filters = this.filters;
		const categories = filters.getActiveCategories().map(c => c.value);
		return this.elements.$packages.filter(function () {
			if ($(this).data('start') && filters.to && $(this).data('start') > filters.to) {
				return false;
			}

			if ($(this).data('end') && filters.from && $(this).data('end') < filters.from) {
				return false;
			}

			if (filters.empty()) return true;

			for (var i = 0; i < categories.length; i++) {
				if ($(this).data('categories').filter(c => c === categories[i]).length > 0) {
					return true;
				}
			}

			return false;
		});
	}

	adjustUI() {
		const $active = this.getFilteredPackages();

		this.elements.$packages.hide();
		$active.show();
	}
}

$(function () {
	$('.packages').each(function () {
		const module = new PackagesModule(this);
	});
});
