$(function () {
	const $search = $('.searchbar');
	const $toggle = $search.find('.searchbar-toggle');
	const $container = $search.find('.searchbar-container');
	const $input = $container.find('.searchbar-input');

	function toggleSearch(state) {
		const shouldOpen = typeof state === 'undefined' ? !$container.data('open') : state;

		$container.data('open', shouldOpen);
		$container.toggleClass('open', shouldOpen);
		$input.attr('tabindex', shouldOpen ? 0 : -1);
		$input.attr('aria-hidden', (!shouldOpen).toString());

		if (shouldOpen) {
			$input.focus();
		} else {
			$search[0].reset();
		}
	}

	$toggle.on('click', function (e) {
		if (!$input.val()) {
			e.preventDefault();
			toggleSearch();

			if(!$container.data('open')) {
				$toggle.focus();
			}
		}
	});

	$search.children().on('focusout', function () {
		window.requestAnimationFrame(function () {
			if (!$search.find(document.activeElement).length) {
				toggleSearch(false);
			}
		});
	});

	$input.on('keydown', function (e) {
		if (e.key === 'Escape') {
			toggleSearch(false);
			$toggle.focus();
		}
	});
});
