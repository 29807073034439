// region jQuery Plugins
import 'slick-carousel';
import 'readmore-js';
import 'dotdotdot';
import 'jquery.maskedinput/src/jquery.maskedinput';
// endregion

// region Other Vendors
import '@fortawesome/fontawesome-pro/js/all.min';
// endregion
import "./site/hero-video";
import "./site/links";
import "./site/navigation";
import "./site/footer";
import "./site/search";
import "./site/form";
import "./site/datepicker";
import "./site/accordion";
import "./site/events";
import "./site/packages";
import "./site/carousels";
import "./site/readmore";
import "./site/blog";
import "./site/virtual-tours";

import * as ADMIN_CONSTANTS from './admin/admin-constants';

window.ADMIN_CONSTANTS = ADMIN_CONSTANTS;
