import moment from 'moment';

$(document).ready(function () {

    if ($('#res-form').length > 0) {
        var dt1 = $("#dt1");
        var dt2 = $('#dt2');

        dt1.datepicker({
            minDate: 0,
            nextText: '<i class="fas fa-angle-right"></i>',
            prevText: '<i class="fas fa-angle-left"></i>',
            onSelect: function (date) {
                var date2 = dt1.datepicker('getDate');
                date2.setDate(date2.getDate() + 1);
                dt2.datepicker('setDate', date2);
                //sets minDate to dt1 date + 1 day
                dt2.datepicker('option', 'minDate', date2);
            }
        });

        dt2.datepicker({
            minDate: '+1',
            nextText: '<i class="fas fa-angle-right"></i>',
            prevText: '<i class="fas fa-angle-left"></i>',
            onClose: function () {
                var date1 = dt1.datepicker('getDate');
                var date2 = dt2.datepicker('getDate');
                if (date2 <= date1) {
                    var minDate = dt2.datepicker('option', 'minDate');
                    dt2.datepicker('setDate', minDate);
                }
            }
        });

        document.getElementById('dt1').value = moment().format('L');
        document.getElementById('dt2').value = moment().add(1, 'day').format('L');

    }


});

