import { scrollToElement } from './helpers';

$(function () {
	$('a[href^=http]').attr('target', '_blank'); // external links

	$(document).on('click', 'a[href^="#"]', function (e) {
		const targetSelector = $(this).attr('href');

		if (targetSelector === '#' && $(this).hasClass('back-to-top')) {
			$('html, body').animate({
				scrollTop: 0
			}, 1000);
			return false;
		}

		if (scrollToElement(targetSelector)) e.preventDefault();
	});
});
