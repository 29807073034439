$(function () {
	const sources = document.querySelectorAll('video#video-hero source');
	const video = document.querySelector('video#video-hero');

	// Normalize content offset
	// top: calc(100vh - 120px - 135px - 96px + 100px); // Bottom of screen - header height - booking height - own height + margin offset (crop)
	const $hero = $('.hero-container');
	const $content = $hero.find('.hero-content');
	const $window = $(window);
	const $res = $('.room-res-container');
	const resHeight = $res.height();

	if ($(window).width() > 480) {
		$content.css('position', 'sticky');
		if ($content.css('position') === 'sticky') {
			$content.prependTo($hero);

			$hero.css('margin-top', -$content.outerHeight());

			const top = $res.outerHeight() + $('#header').outerHeight() - 39; // No idea what's causing a 39px offset

			$content.css('top', `calc(100vh - (${top}px - 20px))`);
		} else {
			requestAnimationFrame(function drawLoop() { // Using draw loop instead of event listeners to handle all edge cases
				const vh = $window.height();
				const offset = vh - resHeight;
				const pos = $window.scrollTop() + offset;
				const heroBottom = $hero.offset().top + $hero.height();
				const bottomProp = heroBottom - pos;

				$content.css({
					top: 'auto',
					bottom: Math.max(0, bottomProp)
				});
				requestAnimationFrame(drawLoop);
			});
		}
	}

	if (video) {
		for (var i = 0; i < sources.length; i++) {
			sources[i].setAttribute('src', sources[i].getAttribute('data-src'));
		}
		if (window.innerWidth > 480) {
			video.load();
		}
	}

});
