export const isEditMode = $('body').hasClass('edit-mode');

export function randomBetween(min, max) {
	return Math.random() * (max - min) + min;
}

export function constrain(val, min, max) {
	return Math.min(max, Math.max(val, min));
}

export function randomIndexInArray(arr) {
	return Math.floor(randomBetween(0, arr.length));
}

export function randomFromArray(arr) {
	return arr[randomIndexInArray(arr)];
}

export function scrollToElement(selector) {
	if (selector === '#') return false;

	const $elem = $(selector);

	if (!$elem.length) return false;

	const offsetElements = $elem.parents('[data-scroll-offset]');
	const parentOffsets = offsetElements.toArray().reduce((a, b) => a + $(b).data('scrollOffset'), 0);

	$('html, body').animate({
		scrollTop: $elem.offset().top - parentOffsets
	}, {
		complete() {
			$elem.trigger('scrollto');
		}
	}, 1000);

	return true;
}
