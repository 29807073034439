$(function () {
	$('.virtual-tour').each(function () {
		const $container = $(this);
		const $frame = $container.find('.virtual-tour-frame');
		const $overlay = $frame.find('.virtual-tour-overlay');

		$overlay.on('click', function () {
			$frame.addClass('interactive');
		});

		$frame.on('mouseleave', function () {
			$frame.removeClass('interactive');
		});

		$(window).on('scroll', function () {
			$frame.removeClass('interactive');
		});
	});
});
